<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/user_1.png"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Исъҳоқхон Ибрат<br />(1862-1937)</p></b-card-text
          >
        </b-col>
        <b-col md="6">
          <b-card-text>
          <p class="TextBlack17">
            Исъҳоқхон Ибрат (1862-1937) – маърифатпарвар шоир, забардаст
            тилшунос, тарихшунос олим, илк ўзбек матбаатчиларидан бўлиб,
            1862-йилда Наманган яқинидаги Тўрақўрғон қишлоғида туғилган.
            Дастлабки маълумотни ески мактабда, сўнгра онасининг қўлида олади.
            Кейинроқ Қўқонга бориб мадрасага ўқишга киради. Исъҳоқхон Ибрат
            1886-йилда мадрасани тугатиб, Тўрақўрғонга қайтиб келади. У ўз
            фаолиятини педагог сифатида қишлоқда маърифат тарқатиш билан
            бошлади. Ўша йили ески маҳаллий мактаблардан анча фарқ қилувчи
            янгича мактаб очади. Исъҳоқхон Ибрат 1887-йилда ҳаж сафарига
            отланади. Сўнг у Шарқ мамлакатлари ўйлаб саёҳатни давом еттиради.
            Йевропанинг Истанбул, София, Aфина, Рим каби марказий шаҳарларида
            бўлди, анча вақт Жидда шаҳрида истиқомат қилади, Мумбай ва
            Калкуттада яшайди. Исъҳоқхон у йерларда кўр ишлатиладиган араб,
            форс, ҳинд ва инглиз тилларини мукаммал ўрганди. У 1896-йилда ўз
            ватанига қайтиб келди. 1901-йилда „Луғати ситта-алсина“ асарини
            босмадан чиқаради. Мазкур луғат жадид мактабларида шарқ ва рус
            тилларини ўрганишда бирдан бир қўлланма сифатида фойдаланиб келинди.
            Исъҳоқхон Ибрат 1912-йилда ёзувлар тарихига бағишланган „Жомеъ
            ул-хутут“ („Ёзувлар мажмуаси“) асарини яратди ва ўз матбааси
            „Матбаайи Исъҳоқия“да босмадан чиқарди. Ибрат чин қалбдан ўз
            халқининг илмли, маърифатли бўлишини истади. Кейинги 20-йил ичида 14
            та илмий-:,. тарихий, лингвистик асар ёзди. 30 йиллик поетик
            ижодининг мажмуйи бўлмиш „Девони Ибрат“ шеърлар тўпламини тузди.
            Тарихшуносликка оид „Тарихи Фарғопа“, „Тарихи маданият“ ва „Мезон
            уз-замон“ илмий асарларини яратди. Ибратнинг сўнгги йиллардаги ҳаёти
            анча таҳликали ўтди. 1935-йилдан у ҳамма лавозимлардан олиб
            ташланди. 1937-йилнинг апрел ойида 75 ёшли кекса шоир ва
            маърифатпарварни ҳибсга оладилар. Ибрат Aндижон турмасида вафот
            етади. Исъҳоқхон Ибрат қабрининг қайердалиги номаълум.
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
        
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
